import React from "react";

function Ico_menu(props:any){
    return(
<svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} viewBox="0 -960 960 960"><path d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-400Zm0 80q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm0 236q45-14 83-41t69-65l-72-72q-18 11-38.5 16.5T480-320q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 22-5.5 42.5T618-398l59 59q20-40 31.5-84.5T720-516v-189l-240-90-240 90v189q0 121 68 220t172 132Z"/></svg>
    )
}



export default Ico_menu

