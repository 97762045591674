import React from "react";
import Ico_menu from "../assets/Icons/Ico_menu";
import { fadeinTop } from "../Styles/keyframes";
import Flex from "../Styles/styledComponent/Flex";
import Text from "../Styles/styledComponent/Text";
import { Link } from "react-router-dom";

function Nav() {
    return (
        <Flex align={"end"} justify={"end"} css={{
            width: "100%",
            paddingTop: "$2",
            paddingBottom: "$2",
            "& svg": {
                fill: "$onBg"
            },
            "& svg[id=menu]": {
                display: "none",
                cursor: "pointer",
                fill: "$onBg800",
                "&:hover": {
                    fill: "$onBg",
                }
            },
            "@bp2": {
                "& svg[id=menu]": {
                    display: "block"
                },
            },
            animation: `${fadeinTop} 1s `
        }}>

            <Flex justify={"end"} align="center" css={{
                "@bp2": {
                    display: "none"
                }
            }}>

                <Flex css={{
                    "& p": {
                        headline6: "700",
                        cursor: "pointer ",
                        padding: "0 $3",
                        color: "$onBg800",
                        "&:hover": {
                            color: "$onBg"
                        }
                    },
                    marginRight: "$6",
                    "@bp2": {
                        marginRight: "$3",
                    }

                }}>
                    <Link to="/privacy" style={{
                        textDecoration: "none"
                    }}><Text>Privacy Policy</Text></Link>
                </Flex>

            </Flex>

            <Link to="/privacy">
                <Ico_menu width="32" height="32" id="menu" />
            </Link>

        </Flex>
    )
}


export default Nav