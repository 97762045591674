import React from 'react';
import { fadein } from "../Styles/keyframes";
import Flex from "../Styles/styledComponent/Flex";
import Text from "../Styles/styledComponent/Text";

function Main() {
    return (
        <Flex dir={"column"} justify="center" overflow="hidden" css={{

            width: "100%",
            height: "100%",
            opacity: "0",
            animation: `0.5s ease-in 0.5s 1 both ${fadein}`,

        }}>
            <Flex dir={"row"} justify="around">
                <Flex dir={"column"} css={{
                    paddingRight: "$3"
                }}>
                    <Flex dir={"row"} css={{
                        paddingTop: "$3",
                    }}>
                        <Text css={{
                            subhead2: "500",
                            background: 'rgba(0, 27, 61,0.1)',
                            padding: "$1",
                            borderRadius: "6px",
                            "@bp4": {
                                subhead1: "400"
                            },

                        }}>
                            App to record your travel expense
                        </Text>
                    </Flex>

                    <Flex dir={"row"} css={{
                        paddingTop: "$3"
                    }}>
                        <Text css={{
                            display1: "400",
                            "@bp2": {
                                display2: "800",
                            },
                            "@bp4": {
                                display3: "800"
                            },
                            "@bp5": {
                                headline1: "800"
                            }

                        }}>
                            Trip
                        </Text>
                        <Text css={{
                            display1: "600",
                            color: "$primary800",
                            "@bp2": {
                                display2: "800",
                            },
                            "@bp4": {
                                display3: "800"
                            },
                            "@bp5": {
                                headline1: "800"
                            }

                        }}>
                            Mate
                        </Text>
                    </Flex>

                    <Text css={{
                        headline5: "500",
                        paddingTop: "$4",
                        "@bp4": {
                            subhead1: "400"
                        }

                    }}>
                        With TripMate, worry less about what goes out of your wallet,<br /> and focus more on what comes out of your travels.
                    </Text>

                    <Flex css={{
                        paddingTop: "$5"
                    }}>
                        <a href="https://play.google.com">
                            <img
                                style={{
                                    width: 168, marginLeft: -12,
                                }}
                                src="https://storage.googleapis.com/covidwentthere_mock/google-play-badge.png" />
                        </a>

                    </Flex>
                    <Flex dir={"column"} css={{
                        display: "none",
                        paddingTop: "$3",
                        "@bp1": {
                            display: "block"
                        }
                    }}>
                        <img
                            style={{
                                width: "100%",
                                height: 240,
                                objectFit: "cover",
                                objectPosition: "0% 0%"
                            }}
                            src="https://storage.googleapis.com/covidwentthere_mock/preview.gif" />
                    </Flex>
                </Flex>
                <Flex dir={"column"} css={{
                    "@bp1": {
                        display: "none"
                    }
                }}>
                    <img
                        style={{
                            width: 280,
                            height: "100%",
                            objectFit: "contain",
                            objectPosition: "0% 0%"
                        }}
                        src="https://storage.googleapis.com/covidwentthere_mock/preview_frame.gif" />
                </Flex>
            </Flex>

        </Flex>
    )
}


export default Main