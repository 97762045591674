import React from 'react';
import { fadein } from "../Styles/keyframes";
import Flex from "../Styles/styledComponent/Flex";
import Text from "../Styles/styledComponent/Text";

function PrivacyPolicy() {
    return (
        <Flex dir={"column"} justify="start" overflow="scrollY" css={{
            paddingTop: "$7",
            paddingBottom: "$7",
            width: "50%",
            opacity: "0",
            animation: `0.5s ease-in 0.5s 1 both ${fadein}`,
            "@bp1": {
                width: "70%"
            },
            "@bp2": {
                width: "80%"
            },
            "@bp3": {
                width: "90%"
            },

        }}>
            <Text css={{
                display1: "400",
                "@bp2": {
                    display2: "800",
                },
                "@bp4": {
                    display3: "800"
                },
                "@bp5": {
                    headline1: "800"
                }

            }}>
                Privacy Policy
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.<br /><br />
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                Summary of key points
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                This summary provides key points from our privacy notice, but you can find out more details about any of the topics below.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>What
                    personal information do we process?</strong> When you visit, use, or navigate our Services, we
                may process personal information depending on how you interact with us and the Services, the choices
                you make, and the products and features you use.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>
                    Do we process any sensitive personal information?</strong> We do not process sensitive personal information
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>How do
                    we process your information?</strong> We process your information to provide, improve, and
                administer our Services, communicate with you, for security and fraud prevention, and to comply with
                law. We may also process your information for other purposes with your consent. We process your
                information only when we have a valid legal reason to do so.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>In
                    what situations and with which parties do we share personal
                    information?</strong> We may share information in specific situations and with specific third parties.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>How do
                    we keep your information safe?</strong> We have organisational and technical processes and procedures in place to
                protect your personal information. However, no electronic transmission over the internet or
                information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly
                collect, access, steal, or modify your information.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                1. WHAT INFORMATION DO WE COLLECT?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>Personal information you disclose to us</strong>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We collect personal information that you provide to us.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We collect personal information that you voluntarily provide to us when you

                express an interest in obtaining information
                about us or our products and Services, when you participate in activities on the Services, or
                otherwise when you contact us.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>Sensitive Information.</strong>We do not process sensitive information.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>Application Data.</strong> If you use our application(s), we also
                may collect the following information if you choose to provide us with access or permission:
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em>Mobile Device Data.</em> We automatically collect
                device information (such as your mobile device ID, model, and manufacturer), operating
                system, version information and system configuration information, device and application
                identification numbers, browser type and version, hardware model Internet service
                provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If
                you are using our application(s), we may also collect information about the phone
                network associated with your mobile device, your mobile device’s operating system or
                platform, the type of mobile device you use, your mobile device’s unique device ID, and
                information about the features of our application(s) you accessed.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                This information is primarily needed to maintain the
                security and operation of our application(s), for troubleshooting, and for our internal
                analytics and reporting purposes.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                All
                personal information that you provide to us must be true, complete, and accurate,
                and you must notify us of any changes to such personal
                information.
            </Text><Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>Information automatically collected</strong>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or
                    browser and device characteristics — is collected automatically
                    when you visit our
                    Services.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We automatically collect certain information when you visit, use, or navigate the
                Services. This information does not reveal your specific identity (like your name or
                contact information) but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system, language preferences,
                referring URLs, device name, country, location, information about how and when you
                use our Services, and other technical information. This information is primarily
                needed to maintain the security and operation of our Services, and for our internal
                analytics and reporting purposes.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                The
                information we collect includes:
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage, and performance information our servers
                automatically collect when you access or use our Services and which we record in
                log files. Depending on how you interact with us, this log data may include your
                IP address, device information, browser type, and settings and information about
                your activity in the Services such as the
                date/time stamps associated with your usage, pages and files viewed, searches,
                and other actions you take such as which features you use), device event
                information (such as system activity, error reports (sometimes called 'crash dumps'),
                and hardware settings).
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                2. HOW DO WE PROCESS YOUR INFORMATION?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We process your information to provide, improve, and administer our Services,
                    communicate with you, for security and fraud prevention,
                    and to comply with law. We may also process your
                    information for other purposes with your
                    consent.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>We process your personal information
                    for a variety of reasons, depending on how you interact with our
                    Services, including:</strong>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or our contractual obligations, to protect your rights, or our legitimate business interests.
                    consent.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <u><em><strong>If you are located in the EU or UK this section applies to you.</strong></em></u>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}><strong>Consent.</strong> We may process your information if you have given us permission (i.e consent to use your personal information for a specific purpose You can withdraw your consent at any time.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}><strong>Vital
                Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <u><em><strong>If you are located in Canada this section applies to you.</strong></em></u>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent).
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - For investigations and fraud detection and prevention
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - For business transactions provided certain conditions are met
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - If it is contained in a witness statement and the collection is necessary to assess process or settle an insurance claim
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - For identifying injured ill or deceased persons and communicating with next of kin
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"
            }}> - If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - If disclosure is required to comply with a subpoena warrant court order or rules of the court relating to the production of records
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - If it was produced by an individual in the course of their employment business or profession and the collection is consistent with the purposes for which the information was produced
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}> - If the collection is solely for journalistic, artistic, or literary purposes
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We may need to share your personal information in the following situations:
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <strong>Business Transfers.</strong> We may share or transfer your information in connection with or during negotiations of any merger sale of company assets financing or acquisition of all or a portion of our business to another company.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                5. HOW LONG DO WE KEEP YOUR INFORMATION?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax accounting or other legal requirements).
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                When we have no ongoing legitimate business need to process your personal information we will either delete or anonymise such information or if this is not possible (for example because your personal information has been stored in backup archives then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We aim to protect your personal information through a system of organisational and technical security measures.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process However despite our safeguards and efforts to secure your information no electronic transmission over the Internet or information storage technology can be guaranteed to be 100 secure so we cannot promise or guarantee that hackers cybercriminals or other unauthorised third parties will not be able to defeat our security and improperly collect access steal or modify your information Although we will do our best to protect your personal information transmission of personal information to and from our Services is at your own risk You should only access the Services within a secure environment.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                7. DO WE COLLECT INFORMATION FROM MINORS?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us as described below.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                8. DO WE MAKE UPDATES TO THIS NOTICE?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                <em><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </Text>

            <Text css={{
                headline3: "500",
                paddingTop: "$4"

            }}>
                8. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </Text>

            <Text css={{
                subhead1: "500",
                paddingTop: "$4"

            }}>
                If you have questions or comments about this notice, you may email us at:
            </Text>

<Text css={{
    headline5: "500",
    paddingTop: "$2",
    color: "$primary"

}}>
    a0101774@u.nus.edu
</Text>

        </Flex >
    )
}


export default PrivacyPolicy