import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ReactDOM from 'react-dom/client';
import Landing from './Landing';
import Privacy from './Privacy';

export default function Index() {
  return (
    <BrowserRouter>
      <Routes><Route path="/" element={<Landing />} /></Routes>
      <Routes><Route path="privacy" element={<Privacy />} /></Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);